import React from "react";

function TestimonialItem({ testimonial, person }) {
  return (
    <div className="w-full flex flex-col justify-center items-center text-white font-thin py-32 bg-transparent">
      <p className="text-3xl mb-4">{`"${testimonial}"`}</p>
      <p className="text-xl uppercase">{person}</p>
    </div>
  );
}

export default TestimonialItem;
