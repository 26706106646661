import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import TestimonialItem from "./TestimonialItem";

import testimonialBg from "../img/footer-slider.jpg";

const TestimonialSlider = () => {
  return (
    <div
      className="flex justify-center items-center w-full h-84 bg-center bg-cover relative"
      style={{ backgroundImage: `url(${testimonialBg})` }}
    >
      <Carousel
        infiniteLoop
        dynamicHeight
        showStatus={false}
        autoPlay
        transitionTime={1000}
        interval={7000000}
        showThumbs={false}
        showArrows={false}
        width="90%"
        className="w-full z-20 flex items-center justify-center"
      >
        <TestimonialItem
          testimonial="Dr. Zadeh never rushes you in/out of her office. She listens intently and is always cheerful, compassionate, and professional."
          person="Sherry S"
        />
        <TestimonialItem
          testimonial="Dr. Fatemi is definitely one of the few doctors I have been to that I trust explicitly. He takes time to listen and explain."
          person="G Smith"
        />
        <TestimonialItem
          testimonial="My wife and I have been a patient of Dr. Koruth for about 15 years and I think he is absolutely wonderful."
          person="Kayne"
        />
      </Carousel>
      <div className="w-full h-full absolute opacity-75 z-10 bg-black"></div>
    </div>
  );
};

export default TestimonialSlider;
